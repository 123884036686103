import { createApp } from "vue";
import App from "./App.vue";
import router from './router'
import { createI18n } from 'vue-i18n';
import { locales } from './locales/messages';
import { createMetaManager } from 'vue-meta';
import { plugin as vueMetaPlugin } from "vue-meta";
import { createHead } from '@vueuse/head';

import "swiper/css";
import './assets/css/font-awesome.min.css'
import './assets/css/pe-icon-7-stroke.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style.scss'

const app = createApp(App);

// Use the MetaManager with your app
app.use(createMetaManager());
app.use(vueMetaPlugin);
app.use(createHead());

const i18n = createI18n({
    locale: 'en',
    messages: locales,
});

// Use router and i18n with your app
app.use(router);
app.use(i18n);

// Mount your app
app.mount("#app");
