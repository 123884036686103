import en from "./en.json";
import es from "./es.json";
import ru from "./ru.json";
import ar from "./ar.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import ja from "./ja.json";
import uk from "./uk.json";
import nl from "./nl.json";
import no from "./no.json";
import sv from "./sv.json";
import fi from "./fi.json";
import cs from "./cs.json";
import pl from "./pl.json";
import el from "./el.json";
import vl from "./vl.json";
import tr from "./tr.json";
import fa from "./fa.json";
import ta from "./ta.json";
import ko from "./ko.json";
import bn from "./bn.json";
import pt from "./pt.json";
import hi from "./hi.json";
import zh from "./zh.json";

export const locales = {
  en,
  es,
  ru,
  ar,
  de,
  fr,
  it,
  ja,
  uk,
  nl,
  no,
  sv,
  fi,
  cs,
  pl,
  el,
  vl,
  tr,
  fa,
  ta,
  ko,
  bn,
  pt,
  hi,
  zh,
};
